import React from "react";
import { Routes, Route } from "react-router-dom";
import AboutUs from "../Pages/AboutUs";
import Career from "../Pages/Career";
import CaseStudy from "../Pages/CaseStudy";
import HireUs from "../Pages/HireUs";
import Home from "../Pages/Home";
import ServiceUIUX from "../Pages/Services/ServiceUIUX";
import ServiceWeb from "../Pages/Services/ServiceWeb";
import ServiceMobile from "../Pages/Services/ServiceMobile";
import ServiceTesting from "../Pages/Services/ServiceTesting";
import ServiceProdDevelop from "../Pages/Services/ServiceProdDevelop";
import ServiceDeveloper from "../Pages/Services/ServiceDeveloper";
import ContactUs from "../Pages/ContactUs";
import ClientTechHire from "../Components/pageComponent/ClientTechHire";
import NotFound from "../Pages/NotFound";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import TechnologyDetails from "../Pages/TechnologyDetails";
import ServiceAiMl from "../Pages/Services/ServiceAiMl";
import Jobs from "../Pages/Jobs";

const WebRoutes = () => {
  // const location = useLocation();
  // useEffect(() => {
  //   if (location.pathname !== "/") {
  //     alert("Unauthorised");
  //   }
  // }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/career" element={<Career />} />
        <Route path="/career/jobs" element={<Jobs />} />
        <Route path="/career/jobs/:id" element={<Jobs />} />
        <Route path="/hire-us" element={<HireUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/case-study" element={<CaseStudy />} />
        <Route path="/ai-ml" element={<ServiceAiMl />} />
        <Route path="/services-ui-ux" element={<ServiceUIUX />} />
        <Route path="/services-web-development" element={<ServiceWeb />} />
        <Route
          path="/services-mobile-development"
          element={<ServiceMobile />}
        />
        <Route path="/services-testing" element={<ServiceTesting />} />
        <Route
          path="/services-product-development"
          element={<ServiceProdDevelop />}
        />
        <Route path="/services-developer" element={<ServiceDeveloper />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/client-hire" element={<ClientTechHire />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/technology-details/:name" element={<TechnologyDetails />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default WebRoutes;
