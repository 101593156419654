import React, { useEffect, useState } from 'react'
import Layout from '../Components/utils/Layout'
import HomeBottomBG1 from "../assets/common/svgs/BottomBG.svg";
import DeviderLine from '../Components/DeviderLine';
import JobCard from '../Components/JobCard';
import { useNavigate, useParams } from 'react-router-dom';
import { JOBS } from '../technology/data';
import IMG from "../assets/common/images/JobImg.png";
import useWindowWidth from '../utils/useWindowsize';
import JobForm from '../Components/JobForm';


const Jobs = () => {
  const [hoverIndex, setHoverIndex] = useState(null)
  const [selectedJob, setSelectedJob] = useState()
  // const navigate = useNavigate()
  // const windowWidth = useWindowWidth()
  // const isDesktop = windowWidth < 1300
  // const { id } = useParams()
  // useEffect(() => {
  //   if (id) {
  //     const job = JOBS.find((job) => job.id == id);
  //     console.log("job", job);

  //     setSelectedJob(job)
  //   }
  // }, [id])
  const [isApply, setIsApply] = useState(false)
  const onApply = (obj) => {
    setIsApply(true)
    setSelectedJob(obj)
  }
  return (
    <Layout>

      <div className="relative mt-[100px] flex h-screen items-center justify-center pb-[350px]">
        <div className="absolute bottom-[-5px] flex h-[344px] w-full justify-center">
          <img
            draggable="false"
            className="h-full object-cover"
            src={HomeBottomBG1}
            alt=""
          />
        </div>

        <div className="flex flex-col items-center justify-center text-center">
          <div className="HeadingText  mt-[100px] max-w-[855px] max_md:px-[50px] max_sm:px-[20px]">
            Find your job dream job here easy and fast
          </div>
          <div className="SubHeadingText mt-[40px] max-w-[770px] max_md:px-[50px] max_sm:px-[20px]">
            Discover endless possibilities in the world of Software with iGeek. Unleash your potential, build your skills, and make a lasting
            impact as we strive to revolutionize the industry. Join us and shape the future of technology today.
          </div>
        </div>
      </div>

      <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
        <DeviderLine title="JOB" right="right-[25%]" />

        {!isApply && (

          <div className="mt-[120px] max_sm:mt-[60px] mb-[80px] max_sm:mb-10 flex flex-col items-center justify-center">
            <div
              className={`w-full text-center PageTitle font-bold text-duskwood`}
            >
              Current job opportunities
            </div>
          </div>
        )}

        {/* {id && selectedJob ? (<>
          <div className="flex max_sm:flex-col max_sm:items-center max_sm:justify-center">
            <div className="h-[76px] w-[76px] min-w-[76px] max_md:h-[50px] max_md:w-[50px] max_md:min-w-[50px]">
              <img
                draggable="false"
                className="h-full object-cover"
                src={IMG}
                alt=""
              />
            </div>
            <div className='flex justify-between'>

            <div className="flex w-full flex-col justify-between py-[5px] pl-[15px] max_xl:py-[8px] max_sm:pl-[0px]">
              <div className="flex items-center justify-between max_sm:mb-[10px]">
                <div className="text-LargeSize font-semibold text-duskwood max_md:text-[13px]">
                  {selectedJob.title}
                </div>
                {isDesktop && (
                  <div className="cursor-pointer text-SmallSize font-medium text-her-sub-heading">
                    <div
                      className={`flex h-[28px] w-[90px] items-center justify-center rounded-[4px] border-[1px] border-hazy-daze hover:border-activeMenu text hover:bg-activeMenu hover:text-white`}
                    >
                      <div className="text-[11px] font-semibold max_md:text-[10px] ">
                        View Detail
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex w-full items-end gap-5">
                <div className="text-SmallSize font-medium text-bateau max_md:text-[10px]">
                  Location:{" "}
                  <span className="text-[13px] font-semibold text-duskwood max_xl:text-[13px] max_md:text-[10px]">
                    {selectedJob.location}
                  </span>
                </div>
                <div className="text-SmallSize font-medium text-bateau  max_md:text-[10px]">
                  Experience:{" "}
                  <span className="text-[13px] font-semibold text-duskwood max_xl:text-[13px] max_md:text-[10px]">
                    {selectedJob.experience}
                  </span>
                </div>
                <div className="text-SmallSize font-medium text-bateau  max_md:text-[10px]">
                  Position:{" "}
                  <span className="text-[13px] font-semibold text-duskwood max_xl:text-[13px] max_md:text-[10px]">
                    {selectedJob.position}
                  </span>
                </div>
                
              </div>
             
            </div>
            <div className="cursor-pointer text-SmallSize font-medium text-her-sub-heading max_xl:hidden">
                  <div
                    className={`flex h-[34px] w-[90px] items-center justify-center rounded-[4px] border-[1px] border-hazy-daze hover:border-activeMenu text hover:bg-activeMenu hover:text-white`}
                  >
                    <div className="text-[11px] font-semibold " onClick={() => navigate(`/career/jobs/${id}`)}>
                      View Detail
                    </div>
                  </div>
                </div>
            </div>
          </div>

        </>) : ( */}
        {isApply ? (<JobForm technology={selectedJob.title} />) : (
          <div className='flex flex-col gap-10'>
            {JOBS.map((data, index1) => (
              <>
                <div>
                  <p key={index1} className='text-[20px] font-bold text-duskwood max_sm:text-[15px]'>{data.title}</p>
                  <div className="grid grid-cols-2 gap-[20px] max_md:grid-cols-1 mt-5 max_sm:mt-4">
                    {data.jobs.map((itm, index) => (
                      <div key={index}>
                        <JobCard
                          title={itm.title}
                          location={itm.location}
                          experience={itm.experience}
                          position={itm.position}
                          id={itm.id}
                          object={itm}
                          hoverIndex={hoverIndex} setHoverIndex={setHoverIndex}
                          onApply={onApply}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ))}
          </div>
        )}

        {/* )} */}

      </div>
    </Layout>
  )
}

export default Jobs
