import React, { Fragment, useState } from "react";
import HomeBottomBG1 from "../assets/common/svgs/BottomBG.svg";
import BenefitCard from "../Components/BenefitCard";
import DeviderLine from "../Components/DeviderLine";
import Layout from "../Components/utils/Layout";
import CalenderIMG from "../assets/common/svgs/calendar.svg";
import BonusIMG from "../assets/common/svgs/bonus.svg";
import CareerIMG from "../assets/common/svgs/career.svg";
import PaidLeaveIMG from "../assets/common/svgs/paidleave.svg";
import FriendlyIMG from "../assets/common/svgs/friendly.svg";
import FestivalIMG from "../assets/common/svgs/festival.svg";
import TechIMG from "../assets/common/svgs/technology.svg";
import ChampIMG from "../assets/common/svgs/teamwork.svg";
import TourIMG from "../assets/common/svgs/tour.svg";

import { ButtonGradientIcon } from "../Components/utils/Buttons";
import { useNavigate } from "react-router-dom";
import JobCard from "../Components/JobCard";

const Career = () => {
  const navigate = useNavigate()
  const [Benefits] = useState([
    {
      IMG: CalenderIMG,
      title: "5 Day Working",
    },
    {
      IMG: BonusIMG,
      title: "Performance Bonus",
    },
    {
      IMG: CareerIMG,
      title: "Career Growth",
    },
    {
      IMG: PaidLeaveIMG,
      title: "Leave Encashment",
    },
    {
      IMG: FriendlyIMG,
      title: "Friendly Culture",
    },
    {
      IMG: FestivalIMG,
      title: "Festival Celebration",
    },
    {
      IMG: TechIMG,
      title: "Trending Technology",
    },
    {
      IMG: ChampIMG,
      title: "Awards",
    },
    {
      IMG: TourIMG,
      title: "Annual Picnic",
    },
  ]);

  const [Jobs, setJobs] = useState([
    {
      title: "React JS Developer",
      location: "Surat",
      experience: "1 to 2 Years",
      position: "2",
    },
    {
      title: "React JS Developer",
      location: "Surat",
      experience: "1 to 2 Years",
      position: "2",
    },
    {
      title: "React JS Developer",
      location: "Surat",
      experience: "1 to 2 Years",
      position: "2",
    },
    {
      title: "React JS Developer",
      location: "Surat",
      experience: "1 to 2 Years",
      position: "2",
    },
    {
      title: "React JS Developer",
      location: "Surat",
      experience: "1 to 2 Years",
      position: "2",
    },
    {
      title: "React JS Developer",
      location: "Surat",
      experience: "1 to 2 Years",
      position: "2",
    },
  ]);

  return (
    <Layout>
      {/* HERO */}
      <div className="relative mt-[100px] flex h-screen items-center justify-center pb-[350px]">
        <div className="absolute bottom-[-5px] flex h-[344px] w-full justify-center">
          <img
            draggable="false"
            className="h-full object-cover"
            src={HomeBottomBG1}
            alt=""
          />
        </div>

        <div className="flex flex-col items-center justify-center text-center">
          <div className="HeadingText  mt-[100px] max-w-[855px] max_md:px-[50px] max_sm:px-[20px]">
            Join Our Trailblazing Team Where Passion Meets Innovation
          </div>
          <div className="SubHeadingText mt-[40px] max-w-[770px] max_md:px-[50px] max_sm:px-[20px]">
            Dive into a culture where ideas thrive and your growth is prioritized. Be part of a team that's shaping the future.
          </div>
        </div>
      </div>

      <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
        <DeviderLine title="BENEFITS" right="right-[25%]" />
        <div className="my-[60px] flex flex-col items-center justify-center">
          <div
            className={`w-full max-w-[345px] text-center PageTitle font-bold text-duskwood`}
          >
            Benefits to join iGeek
          </div>
        </div>
        <div className="mb-[120px] grid grid-cols-5 gap-[20px] justify-center items-center max_md:mb-[80px] max_md:grid-cols-2 max_sm:grid-cols-1">
          {Benefits.map((itm, index) => (
            <Fragment key={index}>
              <BenefitCard IMAGE={itm.IMG} title={itm.title} />
            </Fragment>
          ))}
        </div>

        <DeviderLine title="JOB" right="right-[50%]" />
        <div className="mt-[60px] mb-[20px] flex flex-col items-center justify-center max_sm:mb-[40px]">
          <div
            className={`w-full max-w-[620px] text-center PageTitle font-bold leading-[33px] text-duskwood`}
          >
            Find your dream job here easy and fast
          </div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <div className="PageContent font-medium w-full max-w-[745px] text-center">
            At iGeek, we take pride in providing top-notch IT services and
            solutions that help our clients achieve their goals. We are
            dedicated to staying ahead of the curve when it comes to the latest
            technological advancements and strive to deliver innovative and
            effective solutions that meet the unique needs of each of our
            clients.
          </div>
        </div>
        <div className="mt-[50px] flex justify-center ">
          <div className="w-fit">
              <ButtonGradientIcon background="#2275A1" title="FIND YOUR POSITION" onClick={()=>navigate('/career/jobs')} />
          </div>
        </div>

        {/* <div className="grid grid-cols-2 gap-[20px] max_md:grid-cols-1">
          {Jobs.map((itm, index) => (
            <div key={index}>
              <JobCard
                title={itm.title}
                location={itm.location}
                experience={itm.experience}
                position={itm.position}
              />
            </div>
          ))}
        </div> */}
      </div>
    </Layout>
  );
};

export default Career;
