import { useEffect, useState } from 'react';
const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // Throttled resize handler
  // const handleResize = useThrottle(() => {
  //   setWindowWidth(window.innerWidth);
  // }, 500); // Adjust the throttle delay as needed
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  }; // Adjust the throttle delay as needed
  useEffect(() => {
    // Set up the event listener
    window.addEventListener("resize", handleResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
  return windowWidth;
};
export default useWindowWidth;