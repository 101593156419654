import React, { useState, Fragment, useRef, useEffect } from "react";
import HomeBottomBG1 from "../assets/common/svgs/BottomBG.svg";
import ContactCard from "../Components/ContactCard";
import Layout from "../Components/utils/Layout";
import OfficeIcon from "../assets/common/svgs/contact/address.svg";
import EmailIcon from "../assets/common/svgs/contact/email.svg";
import CallIcon from "../assets/common/svgs/contact/call.svg";
import InputField from "../Components/utils/InputField";
import { ButtonGradientIcon } from "../Components/utils/Buttons";
import DeviderLine from "../Components/DeviderLine";
import GlassGradient from "../assets/common/svgs/glasscircle.svg";
import Location from "../assets/common/svgs/Location.svg";
import Phone from "../assets/common/svgs/PhoneNumber.svg";
import Email from "../assets/common/svgs/Email.svg";
import ReCAPTCHA from "react-google-recaptcha";
import useStateWithValidation from "../utils/useStateValidation";
import { countryCode } from "../utils/countryCode";
import {
  Button,
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Alert,
} from "@material-tailwind/react";
import ArrICON from "../assets/common/svgs/ArrIcon.svg";
import { SubmitForm } from "../api/apis";
import { ReactComponent as UsaIcon } from "../assets/common/svgs/contact/usa.svg";
import { ReactComponent as IndiaIcon } from "../assets/common/svgs/contact/india.svg";

const ContactUs = () => {
  const recaptchaRef = useRef(null);
  const [show, setShow] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isAllowSubmit, setIsAllowSubmit] = useState(false);
  const [Fname, setFname, isFnameValid, isFnameFoucs, setIsFnameFocus] =
    useStateWithValidation((name) => name.length > 1, "");

  const [email, setEmail, isEmailValid, isEmailFoucs, setIsEmailFocus] =
    useStateWithValidation((email) => {
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return regex.test(email);
    }, "");

  const [
    message,
    setMessage,
    isMessageValid,
    isMessageFoucs,
    setIsMessageFocus,
  ] = useStateWithValidation((message) => message.length > 0, "");

  const [phone, setPhone, isPhoneValid, isPhoneFoucs, setIsPhoneFocus] =
    useStateWithValidation(
      (phone) => phone.length > 5 && phone.length < 11,
      ""
    );

  const [recaptch, setRecaptch, isRecaptchValid, setIsRecaptchFocus] =
    useStateWithValidation((recaptch) => recaptch !== "", "");
  const [cCodd, setCCode] = React.useState("+91");
  useEffect(() => {
    if (
      isFnameValid &&
      isEmailValid &&
      isMessageValid &&
      isPhoneValid &&
      isRecaptchValid
    ) {
      setIsAllowSubmit(true);
    } else {
      setIsAllowSubmit(false);
    }
  }, [email, message, phone, recaptch, Fname]);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, 5000);
    }
  }, [show]);

  const handleSubmit = async () => {
    if (isAllowSubmit) {
      const body = {
        userName: Fname,
        phoneNumber: cCodd + phone,
        email: email,
        text: message,
      };

      const res = await SubmitForm(recaptch, body);
      if (res.status === 200) {
        setShow(true);
        setFname("");
        setEmail("");
        setMessage("");
        setRecaptch("");
        setPhone("");
        setCCode("+91");
        recaptchaRef.current.reset();
      }
    }
  };

  return (
    <Layout>
      {/* HERO SECTION */}
      <div className="relative mt-[100px] flex h-screen items-center justify-center pb-[350px]">
        <div className="absolute bottom-[-5px] flex h-[344px] w-full justify-center">
          <img
            draggable="false"
            className="h-full object-cover"
            src={HomeBottomBG1}
            alt=""
          />
        </div>

        <div className="flex flex-col items-center justify-center text-center">
          <div className="HeadingText  mt-[100px] max-w-[900px] max_md:px-[50px] max_sm:px-[20px]">
            Let Us Help You Achieve Your Technology Goals.
          </div>
          <div className="SubHeadingText mt-[40px] max-w-[858px] max_md:px-[50px] max_sm:px-[20px]">
            We welcome the opportunity to connect with you and explore how our
            expertise and services can help you achieve your digital objectives
          </div>
        </div>
      </div>

      <div
        id="contact-us-redireaction-form"
        className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]"
      >
        <DeviderLine title="CONTACT" right="right-[25%]" />

        <div className="mb-[60px] mt-[90px] flex flex-col items-center justify-center max_md:mb-[30px] max_md:mt-[60px]">
          <div className="flex flex-col items-center justify-center">
            <div className="PageTitle mt-[0px]">Get In Touch</div>
            {/* <div className="PageContent w-full max-w-[992px] text-center">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s.
            </div> */}
          </div>
        </div>
        <div className="mx-auto my-[20px] w-full max-w-[1200px] rounded-[10px] p-[20px] shadow-[0px_6px_30px_rgba(0,0,0,0.05)]">
          <div className="flex w-full flex-row-reverse gap-x-[35px] py-[10px] max_xl:flex-wrap-reverse max_sm:px-[0px]">
            <div className="flex grow flex-col justify-center pr-[15px] max_xl:pr-[0px] max_md:w-full max_md:max-w-full">
              <InputField
                type="text"
                label="Full Name*"
                placeholder="Full Name"
                name="Name"
                className="relative"
                onBlur={() => {
                  setIsFnameFocus(false);
                }}
                value={Fname}
                onChange={(e) => setFname(e.target.value)}
                error={
                  Fname.length === 1 &&
                  !isFnameFoucs &&
                  !isFnameValid &&
                  "Please enter name"
                }
              />
              <div className=" mt-[30px] grid h-fit grid-cols-2 gap-x-[20px] gap-y-[30px] max_md:mt-[20px] max_md:grid-cols-1">
                <InputField
                  type="email"
                  label="Email address*"
                  placeholder="Email address"
                  name="Email"
                  value={email}
                  onBlur={() => {
                    setIsEmailFocus(false);
                  }}
                  onChange={(e) => setEmail(e.target.value.trim())}
                  error={
                    email.length > 1 &&
                    !isEmailFoucs &&
                    !isEmailValid &&
                    "Please enter valid email"
                  }
                />
                <div className="relative">
                  <div className="absolute bottom-[-24px] text-[12px] text-red-400">
                    {((phone.length > 1 && phone.length < 7) ||
                      phone.length > 11) &&
                      !isPhoneFoucs &&
                      !isPhoneValid &&
                      "Please enter valid phone number"}
                  </div>
                  <label
                    className={`text-RegularSize font-medium text-her-sub-heading max_md:text-[14px]`}
                  >
                    {"Phone Number*"}
                  </label>
                  <div
                    className={`bg-moon-white ${isFocused ? "shadow-[0_0_0_1px_#2275a1]" : ""
                      } group mt-[9px] flex h-[50px] rounded-[8px]`}
                  >
                    <Menu placement="bottom-start">
                      <MenuHandler>
                        <Button
                          ripple={false}
                          variant="text"
                          color="blue-gray"
                          className="flex h-full w-[100px] items-center justify-center gap-[10px] p-0 pl-[15px] pr-[10px] font-normal text-duskwood hover:bg-transparent focus-visible:outline-0 active:bg-transparent max_md:text-[14px]"
                        >
                          {cCodd}
                          <img
                            draggable="false"
                            src={ArrICON}
                            className="rotate-[270deg]"
                            alt=""
                          />
                        </Button>
                      </MenuHandler>
                      <div className="h-[35px] w-[1px] min-w-[1px] self-center bg-duskwood"></div>

                      <MenuList className="max-h-[20rem] max-w-[18rem]">
                        {countryCode.map(({ name, code, dial_code }, index) => {
                          return (
                            <Fragment key={index}>
                              <MenuItem
                                key={name}
                                value={name}
                                className="flex items-center gap-2"
                                onClick={() => setCCode(dial_code)}
                              >
                                {name}{" "}
                                <span className="ml-auto">{dial_code}</span>
                              </MenuItem>
                            </Fragment>
                          );
                        })}
                      </MenuList>
                    </Menu>
                    <input
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => {
                        setIsPhoneFocus(false);
                        setIsFocused(false);
                      }}
                      type="text"
                      required="required"
                      autoComplete="off"
                      className="focus-visible:none w-full bg-transparent px-[12px] text-[17px] font-medium text-duskwood focus:outline-none focus:outline-[0px] focus:outline-offset-0 max_xl:px-[16px] max_md:text-[14px]"
                      placeholder={"Phone Number"}
                      name={"Phone"}
                      value={phone}
                      onChange={(e) => {
                        if (
                          /^[0-9]*$/.test(e.target.value) &&
                          e.target.value.trim().length < 11
                        ) {
                          setPhone(e.target.value.trim());
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-[24px]">
                <label
                  className={`text-RegularSize font-medium text-her-sub-heading max_md:text-[14px]`}
                >
                  {" "}
                  Message
                </label>
                <textarea
                  style={{
                    WebkitAppearance: "none",
                  }}
                  type="text"
                  name="Message"
                  autoComplete="off"
                  value={message}
                  placeholder="Type Message....."
                  className="focus-visible:none mt-[9px] h-[250px] w-full rounded-[8px] bg-moon-white p-[24px] text-[17px] font-medium text-duskwood focus:shadow-[0_0_0_1px_#2275a1] focus:outline-none focus:outline-[1px] focus:outline-offset-0 max_xl:h-[200px] max_md:text-[14px]"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="relative mt-[30px] w-full max_md:mb-[20px]">
                <div className="flex flex-wrap items-center justify-between gap-[20px] ">
                  {/* CAPTCHA */}
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LeTjYclAAAAAB9_gSbhJ5wlQwLgecf2qiEKTXE2"
                    onChange={(value) => {
                      setRecaptch(value);
                    }}
                    onExpired={() => {
                      setRecaptch("");
                      recaptchaRef.current.reset();
                    }}
                  />

                  {/* BTN */}
                  <div
                    onClick={() => handleSubmit()}
                    className={`flex ${isAllowSubmit
                      ? "cursor-pointer"
                      : "cursor-not-allowed opacity-70"
                      } h-[50px] w-[246px] items-center justify-center rounded-[8px] border-[1px] border-none border-[#2275A1] bg-[#2275A1] text-SmallSize font-medium text-white max_md:h-[50px] max_md:w-[200px]`}
                  >
                    <div className="text-[20px] font-semibold max_md:text-[18px] ">
                      Submit
                    </div>
                  </div>
                  <Alert
                    show={show}
                    color="green"
                    dismissible={{
                      onClose: () => setShow(false),
                    }}
                    className="absolute bottom-full right-0 max-w-screen-md"
                  >
                    Your request was successfully submitted.
                  </Alert>
                </div>
              </div>
            </div>
            <div
              style={{
                background:
                  "linear-gradient(345.12deg, #4FBD96 -26.09%, #2275A1 100%)",
              }}
              className="relative flex w-[500px] min-w-[500px] flex-col items-start justify-start rounded-[10px] p-[40px] text-white max_xl:mb-[20px] max_xl:w-full max_xl:min-w-full max_md:w-full max_md:max-w-full max_sm:p-[30px]"
            >
              <img
                draggable="false"
                className="absolute right-0 bottom-0"
                src={GlassGradient}
                alt=""
              />

              <div className="mb-[20px]">
                <div className="text-[22px] font-semibold leading-[33px] max_md:text-[18px] max_md:leading-[28px]">
                  Contact Information
                </div>
                <div className="mt-[10px] text-[16px] leading-[24px] max_md:text-[14px] max_md:leading-[22px]">
                  Fill up the form and our Team will get back to you within 24
                  hours.
                </div>
              </div>

              <div className="mb-[20px]">
                <a
                  className="flex items-center gap-[10px]"
                  target="_blank"
                  href="https://maps.app.goo.gl/S2PtvdZ4SqqgjRew9?g_st=ic"
                  rel="noreferrer"
                >
                  <div className="flex cursor-pointer items-center gap-[10px] text-[18px] font-semibold leading-[27px] max_md:text-[14px] max_md:leading-[24px]">
                    <img draggable="false" className="" src={Location} alt="" />
                    USA
                    <UsaIcon />
                  </div>
                </a>
                <div className="mt-[10px] text-[14px] leading-[21px] max_md:text-SmallSize max_md:leading-[19px]">
                  16821 Ponte Cv, Pflugerville, Texas 78660.
                </div>
              </div>

              <div className="mb-[20px]">
                <a
                  className="flex items-center gap-[10px]"
                  target="_blank"
                  href="https://maps.app.goo.gl/5EkiV2G1hNZsAN7y7"
                  rel="noreferrer"
                >
                  <div className="flex cursor-pointer items-center gap-[10px] text-[18px] font-semibold leading-[27px] max_md:text-[14px] max_md:leading-[24px]">
                    <img draggable="false" className="" src={Location} alt="" />
                    India
                    <IndiaIcon />
                  </div>
                </a>
                <div className="mt-[10px] text-[14px] leading-[21px] max_md:text-SmallSize max_md:leading-[19px]">
                  604-605, Laxmi Enclave - 2, opposite Gajera School, Katargam,
                  Surat, Gujarat 395004.
                </div>
              </div>

              <div className="mb-[20px]">
                <a
                  className="flex items-center gap-[10px]"
                  href="https://goo.gl/maps/bWVAQE65Uuivt2Qh7"
                  target="_blank"
                >
                  <div className="flex cursor-pointer items-center gap-[10px] text-[18px] font-semibold leading-[27px] max_md:text-[14px] max_md:leading-[24px]">
                    <img draggable="false" className="" src={Location} alt="" />
                    India
                    <IndiaIcon />
                  </div>
                </a>
                <div className="mt-[10px] text-[14px] leading-[21px] max_md:text-SmallSize max_md:leading-[19px]">
                  227-228, Sovereign Shoppers, beside Sindhu Seva Samiti School,
                  Adajan, Surat, Gujarat 395009.
                </div>
              </div>

              <div className="">
                <a
                  className="flex items-center gap-[10px]"
                  target="_blank"
                  href="https://goo.gl/maps/pRvGaY6J5zeARsVj8"
                  rel="noreferrer"
                >
                  <div className="flex cursor-pointer items-center gap-[10px] text-[18px] font-semibold leading-[27px] max_md:text-[14px] max_md:leading-[24px]">
                    <img draggable="false" className="" src={Location} alt="" />
                    India
                    <IndiaIcon />
                  </div>
                </a>
                <div className="mt-[10px] text-[14px] leading-[21px] max_md:text-SmallSize max_md:leading-[19px]">
                  8, Vasant Blaze, Second Floor, Abrama-Dharampur Rd, Valsad,
                  Gujarat 396002.
                </div>
              </div>
              <div className="mt-[40px] flex items-center gap-[10px] text-[16px] leading-[24px] max_md:text-[14px] max_md:leading-[22px]">
                <a
                  className="flex items-center gap-[10px]"
                  href="tel:+91 79908 37550"
                >
                  <img draggable="false" className="" src={Phone} alt="" />
                  +91 79908 37550
                </a>
              </div>
              <div className="mt-[30px] flex cursor-pointer items-center gap-[10px] text-[16px] leading-[24px] max_md:text-[14px] max_md:leading-[22px]">
                <a
                  className="flex items-center gap-[10px]"
                  href="mailto:info@igeektech.com"
                  onClick={() => "window.location=another.html"}
                >
                  <img draggable="false" className="" src={Email} alt="" />
                  info@igeektech.com
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* FORMS */}
      </div>
    </Layout>
  );
};

export default ContactUs;
