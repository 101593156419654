import React from "react";
import { ReactComponent as ArrowIcon } from "../../assets/common/svgs/Arrow.svg";
import { ReactComponent as PlusCircleIcon } from "../../assets/common/svgs/PlusCircle.svg";
import { useNavigate } from "react-router-dom";

export const ButtonFilled = ({
  title = "Button",
  onClick = () => {},
  className = "",
}) => {
  return (
    <div
      onClick={onClick}
      // className="rounded-full bg-blue-500 px-5 py-3 text-base mb-3 font-medium text-white transition duration-200 hover:bg-blue-600 active:bg-blue-700"
      className={`F-JC-AI-CENTER text-RegularSize font-semibold cursor-pointer rounded-ButtonRadius border-[1px] border-bateau bg-bateau py-[8px] px-[28px] text-white whitespace-nowrap ${className}`}
    >
      {title}
    </div>
  );
};

export const ButtonGradientIcon = ({
  title = "Button",
  background = "",
  onClick = () => {},
}) => {
  return (
    <div
      style={{
        background: background ? background : "",
      }}
      onClick={onClick}
      className="F-JC-AI-CENTER cursor-pointer gap-x-[10px] rounded-ButtonRadius bg-gradient-to-r from-bateau to-expressionism-green px-[31px] py-[14px]"
    >
      <div className="F-JC-AI-CENTER text-MediumSize font-semibold text-white ">
        {title}
      </div>
      <ArrowIcon />
    </div>
  );
};

export const ButtonOutliedIcon = ({ title = "Button", onClick = () => {} }) => {
  return (
    <div
      onClick={onClick}
      className="F-JC-AI-CENTER cursor-pointer gap-x-[10px] rounded-ButtonRadius border-[1px] border-bateau px-[30px] py-[13px]"
    >
      <div className="F-JC-AI-CENTER text-RegularSize font-semibold text-bateau ">
        {title}
      </div>
      <ArrowIcon className="BlueIcon" />
    </div>
  );
};

export const ServiceButtons = ({
  title = "Button",
  onClick = () => {},
  setcurrentOpen,
  isOpen = false,
  desc = "",
  link = "",
}) => {
  const navigate = useNavigate();
  // const nodeRef = useRef();
  // useOnClickOutside(nodeRef, () => {
  //   // setcurrentOpen("");
  //   console.log("outside");
  // });
  return (
    <div
      style={{
        transition: "height 0.125s ease-out",
      }}
      className={`flex gap-x-[12px]   ${
        isOpen ? "h-[180px] rounded-[22px]" : "h-[45px] rounded-[22px]"
      } trasition bg-white`}
    >
      {isOpen ? (
        <div
          style={{
            display: isOpen ? "flex" : "none",
            opacity: isOpen ? 1 : 0,
            transition: "opacity 200ms, display 200ms",
            // animation: "slideaway 200ms"
            animation: "fadeInOut 600ms",
          }}
          className="flex max-w-[275px] flex-col px-[20px] py-[12px] max_xl:max-w-[210px]"
        >
          <div
            onClick={() => {
              setcurrentOpen("");
            }}
            className="mb-[5px] cursor-pointer text-RegularSize font-semibold text-duskwood"
          >
            {title}
          </div>
          <div className="text-SmallSize">{desc}</div>
          <div className="flex  justify-end ">
            <div
              onClick={() => {
                navigate(link);
              }}
              className="flex h-[28px] w-[28px] cursor-pointer items-center justify-center rounded-full bg-[#F1F1F1]  text-RegularSize font-semibold text-bateau max_md:text-SmallSize"
            >
              <span className="BlueIcon rotate-45 ">
                <ArrowIcon
                  style={{
                    width: "13px",
                    height: "9px",
                  }}
                />
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: isOpen ? "none" : "flex",
            opacity: isOpen ? 0 : 1,
            transition: "opacity 1000ms, display 1000ms",
          }}
          onClick={() => onClick(title)}
          className={`flex cursor-pointer items-center gap-x-[10px] px-[20px] py-[12px]`}
        >
          <PlusCircleIcon />
          <div className="whitespace-nowrap text-RegularSize  font-medium max_md:hidden">
            {title}
          </div>
        </div>
      )}
    </div>
  );
};

export const ServiceBTN = ({ title = "Button" }) => {
  return (
    <div className={`flex gap-x-[12px] rounded-full  bg-white`}>
      <div
        className={`flex cursor-pointer items-center gap-x-[10px] rounded-full px-[20px] py-[12px] max_md:px-[12px]`}
      >
        <PlusCircleIcon />
        <div className="whitespace-nowrap text-RegularSize font-medium capitalize  text-her-sub-heading max_md:hidden">
          {title}
        </div>
      </div>
    </div>
  );
};

export const ServiceContentBTN = ({
  title = "Button",
  desc = "",
  link = "",
}) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col ">
      <div className="mb-[15px] flex justify-between">
        <div className="flex cursor-pointer items-center text-RegularSize font-semibold text-duskwood">
          {title}
        </div>
        <div className="flex  justify-end ">
          <div
            onClick={() => {
              navigate(link);
            }}
            className="flex h-[28px] w-[28px] cursor-pointer items-center justify-center rounded-full bg-[#F1F1F1]  text-RegularSize font-semibold text-bateau max_md:text-SmallSize"
          >
            {/* <span className="BlueIcon w-[10px] "> */}
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.1375 9.61533C7.9625 9.44033 7.8785 9.22887 7.8855 8.98096C7.8925 8.73304 7.98379 8.52158 8.15938 8.34658L10.6313 5.87471H0.875003C0.627086 5.87471 0.419128 5.79071 0.251128 5.62271C0.0831282 5.45471 -0.000580306 5.24704 3.02768e-06 4.99971C3.02768e-06 4.75179 0.0840031 4.54383 0.252003 4.37583C0.420003 4.20783 0.62767 4.12412 0.875003 4.12471H10.6313L8.1375 1.63096C7.9625 1.45596 7.875 1.248 7.875 1.00708C7.875 0.766165 7.9625 0.558498 8.1375 0.384082C8.3125 0.209082 8.52046 0.121582 8.76138 0.121582C9.00229 0.121582 9.20996 0.209082 9.38438 0.384082L13.3875 4.38721C13.475 4.47471 13.5371 4.5695 13.5739 4.67158C13.6106 4.77367 13.6287 4.88304 13.6281 4.99971C13.6281 5.11637 13.6098 5.22575 13.573 5.32783C13.5363 5.42992 13.4744 5.52471 13.3875 5.61221L9.3625 9.63721C9.20209 9.79762 9.00171 9.87783 8.76138 9.87783C8.52104 9.87783 8.31309 9.79033 8.1375 9.61533Z"
                fill="#2275A1"
              />
            </svg>
            {/* </span> */}
          </div>
        </div>
      </div>
      <div className="text-SmallSize text-her-sub-heading">{desc}</div>
    </div>
  );
};
