import React, { useRef, useState } from 'react'
import { useOnClickOutside } from '../../utils/OutsideClick'
import { Dropdown } from '../../assets/common/Icons/icons'

export const InputWithRight = ({ type = "",
  error = "",
  value = "",
  name = "name",
  label = "label",
  placeholder = "Placeholder",
  onChange = () => { },
  onBlur = () => { }, }) => {
  return (
    <div>
      <div className="relative">
        <div className="absolute bottom-[-24px] text-[12px] text-red-400">
          {error}
        </div>
        <label
          className={`text-RegularSize font-medium text-her-sub-heading max_md:text-[14px]`}
        >
          {label && label}
        </label>
        <div className={`flex h-[50px] max_sm:h-[38px] w-full rounded-[8px] ${label ? 'mt-[9px]' : 'mt-[33px]'}`}>
          <input
            style={{
              WebkitAppearance: "none",
            }}
            onBlur={onBlur}
            type={type}
            required="required"
            autoComplete="off"
            className="rounded-tl-[8px] rounded-bl-[8px]  w-[calc(100%-100px)] max_sm:w-[calc(100%-70px)] bg-moon-white px-[24px] text-[17px] font-medium text-duskwood focus:outline-none focus-visible:none  max_xl:px-[16px] max_md:text-[14px]"
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            value={value}
          />
          <div className='w-[100px] max_sm:w-[70px] bg-activeMenu rounded-tr-[8px] rounded-br-[8px] flex justify-center items-center text-white max_sm:text-LaptopSubHeading'>
            {placeholder}
          </div>
        </div>
      </div>
    </div>
  )
}
export const InputWithleft = ({ type = "",
  error = "",
  value = "",
  name = "name",
  label = "label",
  placeholder = "Placeholder",
  onChange = () => { },
  onBlur = () => { }, }) => {
  const [isFocused, setIsFocused] = useState(false)
  return (
    <div className="relative">
      <div className="absolute bottom-[-24px] text-[12px] text-red-400">
        {/* {isPhoneFoucs && !isPhoneValid && "Please enter phone number"} */}
      </div>
      <label
        className={`text-RegularSize font-medium text-her-sub-heading max_md:text-[14px]`}
      >
        {label}
      </label>
      <div
        className={`bg-moon-white ${isFocused
          ? ""
          : "outline-[0px]"
          } group mt-[9px] flex h-[50px] max_sm:h-[38px] rounded-[8px] items-center`}
      >
        <div className='mx-4 flex justify-center items-center text-LaptopSubHeading font-medium text-[#0E3F54] max_sm:text-LaptopSubHeading'>

          INR
        </div>
        <div className='w-[1px] h-[26px] max_sm:h-[18px] bg-[#0E3F54]'></div>
        <input
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          type={type}
          required="required"
          autoComplete="off"
          className="focus-visible:none w-full bg-transparent px-[12px] text-[17px] font-medium text-duskwood focus:outline-none max_xl:px-[16px] max_md:text-[14px]"
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  )
}

export const InputWithDropdown = ({ type = "",
  error = "",
  value = "",
  name = "name",
  label = "label",
  placeholder = "Placeholder",
  list,
  handleClick
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const outsideRef = useRef()
  useOnClickOutside(outsideRef, () => setIsOpen(false));
  return (
    <div className="relative">
      <div className="absolute bottom-[-24px] text-[12px] text-red-400">
        {error}
      </div>
      <label
        className={`text-RegularSize font-medium text-her-sub-heading max_md:text-[14px]`}
      >
        {label}
      </label>
      <div
        style={{
          WebkitAppearance: "none",
        }}
        ref={outsideRef}
        className="relative flex items-center mt-[9px] h-[50px] max_sm:h-[38px] w-full rounded-[8px] bg-moon-white px-[24px] text-[17px] font-medium text-duskwood focus:shadow-[0_0_0_1px_#2275a1] max_xl:px-[16px] max_md:text-[14px] cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        value={value}
      >
        <p className='w-full'> {value ? value : placeholder}</p>

        <div className='absolute right-5 top-6 max_sm:top-[18px]'>
          <Dropdown />
        </div>
        {isOpen && (
          <div className='absolute max-w-[130px] top-[44px] rounded-xl w-full right-0 bg-white p-4 z-50 shadow-[2px_2px_10px_0px_#0000000D]
'>
            <div className='flex flex-col gap-2'>
              {list.map((item, index) => (
                <p key={index} className='cursor-pointer' onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                  handleClick(name, item)
                }}>{item}</p>
              ))}
            </div>
            <div className='absolute top-[-10px] right-3 bg-white w-7 h-5' style={{ clipPath: 'polygon(50% 0, 0% 100%, 100% 100%)' }}></div>
          </div>
        )}
      </div>

    </div>
  )

}

export const InputFieldJob = ({
  type = "",
  error = "",
  value = "",
  name = "name",
  label = "label",
  placeholder = "Placeholder",
  onChange = () => {},
  onBlur = () => {},
}) => {
  return (
    <>
      <div className="relative">
        <div className="absolute bottom-[-24px] text-[12px] text-red-400">
          {error}
        </div>
        <label
          className={`text-RegularSize font-medium text-her-sub-heading max_md:text-[14px]`}
        >
          {label}
        </label>
        <input
          style={{
            WebkitAppearance: "none",
          }}
          onBlur={onBlur}
          type={type}
          required="required"
          autoComplete="off"
          className="focus-visible:none mt-[9px] h-[50px] max_sm:h-[38px] w-full rounded-[8px] bg-moon-white px-[24px] text-[17px] font-medium text-duskwood focus:outline-none max_xl:px-[16px] max_md:text-[14px]"
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
        />
      </div>
    </>
  );
};