import React, { useState, Fragment, useEffect, useRef } from "react";
import DeviderLine from "../Components/DeviderLine";
import Layout from "../Components/utils/Layout";
import { ButtonFilled, ButtonOutliedIcon } from "../Components/utils/Buttons";
import BG1 from "../assets/common/images/CaseStudy/Rectangle.png";
import BG2 from "../assets/common/images/CaseStudy/Circle.png";
import BulletIcon from "../assets/common/svgs/Hireus/BulletPointIcon.svg";
import { AllProjects, technologies } from "../technology/data";
import { useLocation, useNavigate } from "react-router-dom";
import InputField from "../Components/utils/InputField";
import ReCAPTCHA from "react-google-recaptcha";
import useStateWithValidation from "../utils/useStateValidation";
import { countryCode } from "../utils/countryCode";
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Alert,
} from "@material-tailwind/react";
import ArrICON from "../assets/common/svgs/ArrIcon.svg";
import { SubmitForm } from "../api/apis";

const TechnologyDetails = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const [isAllProjects, setIsAllProjects] = useState(false);
  const [heroData, setHeroData] = useState();
  const recaptchaRef = useRef(null);
  const [show, setShow] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isAllowSubmit, setIsAllowSubmit] = useState(false);
  const [Fname, setFname, isFnameValid, isFnameFoucs, setIsFnameFocus] =
    useStateWithValidation((name) => name.length > 1, "");

  const [email, setEmail, isEmailValid, isEmailFoucs, setIsEmailFocus] =
    useStateWithValidation((email) => {
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return regex.test(email);
    }, "");

  const [message, setMessage, isMessageValid] = useStateWithValidation(
    (message) => message.length > 0,
    ""
  );

  const [phone, setPhone, isPhoneValid, isPhoneFoucs, setIsPhoneFocus] =
    useStateWithValidation(
      (phone) => phone.length > 5 && phone.length < 11,
      ""
    );

  const [recaptch, setRecaptch, isRecaptchValid, setIsRecaptchFocus] =
    useStateWithValidation((recaptch) => recaptch !== "", "");
  const [cCodd, setCCode] = React.useState("+91");

  useEffect(() => {
    if (location.pathname.split("&").length > 0) {
      setHeroData(technologies[location.pathname.split("&")[1]]);
    }
  }, []);
  useEffect(() => {
    if (
      isFnameValid &&
      isEmailValid &&
      isMessageValid &&
      isPhoneValid &&
      isRecaptchValid
    ) {
      setIsAllowSubmit(true);
    } else {
      setIsAllowSubmit(false);
    }
  }, [email, message, phone, recaptch, Fname]);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, 5000);
    }
  }, [show]);

  const handleSubmit = async () => {
    if (isAllowSubmit) {
      const body = {
        userName: Fname,
        phoneNumber: cCodd + phone,
        email: email,
        text: message,
      };

      const res = await SubmitForm(recaptch, body);
      if (res.status === 200) {
        setShow(true);
        setFname("");
        setEmail("");
        setMessage("");
        setRecaptch("");
        setPhone("");
        setCCode("+91");
        recaptchaRef.current.reset();
      }
    }
  };

  return (
    <>
      <Layout>
        {/* HERO SECTION */}
        <div
          className={`relative m-auto mt-[100px] flex h-screen w-full max-w-[1240px] flex-row items-center justify-center ${!!heroData?.projects?.length && "pb-[110px]"
            } max_xl:max-w-[940px] max_md:flex-col`}
        >
          <div className="flex w-[50%] max-w-[1920px] items-center justify-center max_md:w-full">
            <div className="py-[40px] pl-[56px] pr-[40px] max_md:py-[20px] max_md:pl-[33px] max_md:pr-[18px]">
              <div
                className="text-[30px] font-bold leading-[45px] text-bateau max_md:mt-[0px] max_md:text-[18px] max_md:leading-[27px]"
                dangerouslySetInnerHTML={{ __html: heroData?.title }}
              ></div>
              <div className="text-her-sub-heading mt-[20px] font-medium text-LargeSize leading-[30px] max_md:mt-[15px] max_md:text-[14px] max_md:leading-[22px]">
                {heroData?.description}
              </div>
              <div className="mt-[30px] flex flex-col gap-[20px] max_md:mt-[15px]">
                {heroData?.subTopics?.map((items, index) => (
                  <div key={index} className="flex items-center gap-[10px]">
                    <div className="flex self-start pt-[3px]">
                      <img
                        draggable="false"
                        className="w-[18px] min-w-[18px] max_md:w-[15px] max_md:min-w-[15px]"
                        src={BulletIcon}
                        alt="Points"
                      />
                    </div>
                    <div className="leading-[21px] ttext-her-sub-heading font-medium text-LargeSize max_md:text-[12px] max_md:leading-[18px]">
                      {items.desc}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex w-[50%] max-w-[1920px] items-center justify-center max_md:w-full max_md:px-[33px]">
            <img draggable="false" src={heroData?.img} alt="" />
          </div>
        </div>
        {!!heroData?.projects?.length && (
          <>
            <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
              <div className="mb-[120px] ">
                <DeviderLine title="PROJECT" right="right-[0%]" />
              </div>
              <div>
                {heroData?.projects?.map((itm, index) => (
                  <Fragment key={index}>
                    <div
                      className={`mt-[200px] max_md:mt-[100px] ${index % 2 === 0 ? "flex-row" : "flex-row-reverse"
                        } flex w-full max_md:flex-col ${index === heroData?.projects?.length - 1 &&
                        "mb-[140px] max_md:mb-[70px]"
                        }`}
                    >
                      <div className="relative flex w-[50%] max-w-[1920px] items-center justify-center max_md:w-full">
                        <img draggable="false" src={itm.IMG} alt="" />
                        {index % 2 === 0 && (
                          <div className="absolute bottom-[-130px] left-[-200px] z-[-1] w-full max_xl:w-[500px] max_sm:h-[450px] max_sm:w-[400px]">
                            <img
                              draggable="false"
                              className="h-full object-cover object-right"
                              src={BG1}
                              alt=""
                            />
                          </div>
                        )}
                        {index % 2 !== 0 && (
                          <div className="absolute bottom-[-120px] right-[-180px] z-[-1]  max_xl:w-[420px] max_sm:h-[450px] max_sm:w-[340px]">
                            <img
                              draggable="false"
                              className="h-full object-cover object-left	"
                              src={BG2}
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                      <div
                        className={`flex w-[50%] flex-col justify-center max_md:w-full max_md:pl-[0px]  ${index % 2 === 0 ? "pl-[70px]" : "pl-[0px]"
                          } `}
                      >
                        <div className="text-[46px] font-bold text-bateau max_xl:text-[40px]">
                          {itm.title}
                        </div>
                        <div className="mb-[25px] mt-[19px] max-w-[488px] text-her-sub-heading text-LargeSize font-medium leading-[30px] max_xl:mt-[14px] max_md:text-SmallSize">
                          {itm.description}
                        </div>
                        <div className="mb-[14px] max-w-[488px] text-LargeSize font-semibold leading-[30px] text-bateau max_md:text-SmallSize">
                          Technology
                        </div>
                        <div className="mb-[25px] grid max-w-[488px] grid-cols-3 gap-[10px] ">
                          {itm.technologies.map((tech, index) => (
                            <div
                              key={index}
                              className="text-her-sub-heading font-medium flex items-center gap-[10px] text-LargeSize leading-[30px] max_md:text-[12px] max_md:leading-[18px]"
                            >
                              <span className=" h-[5px] min-h-[5px] w-[5px] min-w-[5px] rounded-full bg-black-oak"></span>{" "}
                              {tech}
                            </div>
                          ))}
                        </div>
                        <div className="w-fit">
                          {itm.link !== "" ? (
                            <a
                              href={`${itm.link}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <ButtonOutliedIcon title="View" />
                            </a>
                          ) : (
                            <ButtonOutliedIcon title="View" />
                          )}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
              </div>
            </div>

            {!isAllProjects && (
              <div className="flex w-full justify-center">
                <div className="flex h-[80px] w-[230px] items-center justify-center">
                  <ButtonFilled onClick={() => {
                    navigate("/case-study");
                  }} title="View More" className="bg-moon-white border-moon-white !text-bateau py-[21px] px-[71px] !text-MediumSize" />
                </div>
              </div>
            )}
          </>
        )}
        <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
          <div
            className={`mb-[120px] ${!!heroData?.projects?.length && "mt-[120px]"
              }`}
          >
            <DeviderLine title="SCHEDULE" right="right-[0%]" />
          </div>
        </div>

        <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
          <div className="flex w-full flex-col items-center justify-center">
            <div className="text-PageTitle font-bold text-duskwood max_md:mt-[25px] max_md:text-[18px] max_md:leading-[32px]">
              Start your 15-days trial now and see the benefits for yourself.
            </div>
            <div className="pt-[20px] text-center text-LargeSize font-medium leading-[30px] text-stone-cold max_md:text-SmallSize max_md:leading-[20px]">
              Feeling lost on how to start? Just complete this form and schedule
              a meeting to <br /> initiate the process.
            </div>
          </div>
          <div className="flex grow flex-col justify-center pr-[15px] max_xl:pr-[0px] max_md:w-full max_md:max-w-full">
            <InputField
              type="text"
              label="Full Name*"
              placeholder="Full Name"
              name="Name"
              className="relative"
              onBlur={() => {
                setIsFnameFocus(false);
              }}
              value={Fname}
              onChange={(e) => setFname(e.target.value)}
              error={
                Fname.length === 1 &&
                !isFnameFoucs &&
                !isFnameValid &&
                "Please enter name"
              }
            />
            <div className=" mt-[30px] grid h-fit grid-cols-2 gap-x-[20px] gap-y-[30px] max_md:mt-[20px] max_md:grid-cols-1">
              <InputField
                type="email"
                label="Email address*"
                placeholder="Email address"
                name="Email"
                value={email}
                onBlur={() => {
                  setIsEmailFocus(false);
                }}
                onChange={(e) => setEmail(e.target.value.trim())}
                error={
                  email.length > 1 &&
                  !isEmailFoucs &&
                  !isEmailValid &&
                  "Please enter valid email"
                }
              />
              <div className="relative">
                <div className="absolute bottom-[-24px] text-[12px] text-red-400">
                  {((phone.length > 1 && phone.length < 7) ||
                    phone.length > 11) &&
                    !isPhoneFoucs &&
                    !isPhoneValid &&
                    "Please enter valid phone number"}
                </div>
                <label
                  className={`text-RegularSize font-medium text-her-sub-heading max_md:text-[14px]`}
                >
                  {"Phone Number*"}
                </label>
                <div
                  className={`bg-moon-white ${isFocused ? "shadow-[0_0_0_1px_#2275a1]" : ""
                    } group mt-[9px] flex h-[50px] rounded-[8px]`}
                >
                  <Menu placement="bottom-start">
                    <MenuHandler>
                      <Button
                        ripple={false}
                        variant="text"
                        color="blue-gray"
                        className="flex h-full w-[100px] items-center justify-center gap-[10px] p-0 pl-[15px] pr-[10px] font-normal text-duskwood hover:bg-transparent focus-visible:outline-0 active:bg-transparent max_md:text-[14px]"
                      >
                        {cCodd}
                        <img
                          draggable="false"
                          src={ArrICON}
                          className="rotate-[270deg]"
                          alt=""
                        />
                      </Button>
                    </MenuHandler>
                    <div className="h-[35px] w-[1px] min-w-[1px] self-center bg-duskwood"></div>

                    <MenuList className="max-h-[20rem] max-w-[18rem]">
                      {countryCode.map(({ name, code, dial_code }, index) => {
                        return (
                          <Fragment key={index}>
                            <MenuItem
                              key={name}
                              value={name}
                              className="flex items-center gap-2"
                              onClick={() => setCCode(dial_code)}
                            >
                              {name}{" "}
                              <span className="ml-auto">{dial_code}</span>
                            </MenuItem>
                          </Fragment>
                        );
                      })}
                    </MenuList>
                  </Menu>
                  <input
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => {
                      setIsPhoneFocus(false);
                      setIsFocused(false);
                    }}
                    type="text"
                    required="required"
                    autoComplete="off"
                    className="focus-visible:none w-full bg-transparent px-[12px] text-[17px] font-medium text-duskwood focus:outline-none focus:outline-[0px] focus:outline-offset-0 max_xl:px-[16px] max_md:text-[14px]"
                    placeholder={"Phone Number"}
                    name={"Phone"}
                    value={phone}
                    onChange={(e) => {
                      if (
                        /^[0-9]*$/.test(e.target.value) &&
                        e.target.value.trim().length < 11
                      ) {
                        setPhone(e.target.value.trim());
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-[24px]">
              <label
                className={`text-RegularSize font-medium text-her-sub-heading max_md:text-[14px]`}
              >
                {" "}
                Message
              </label>
              <textarea
                style={{
                  WebkitAppearance: "none",
                }}
                type="text"
                name="Message"
                autoComplete="off"
                value={message}
                placeholder="Type Message....."
                className="focus-visible:none mt-[9px] h-[250px] w-full rounded-[8px] bg-moon-white p-[24px] text-[17px] font-medium text-duskwood focus:shadow-[0_0_0_1px_#2275a1] focus:outline-none focus:outline-[1px] focus:outline-offset-0 max_xl:h-[200px] max_md:text-[14px]"
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div className="relative mt-[30px] w-full max_md:mb-[20px]">
              <div className="flex flex-wrap items-center justify-between gap-[20px] ">
                {/* CAPTCHA */}
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="6LeTjYclAAAAAB9_gSbhJ5wlQwLgecf2qiEKTXE2"
                  onChange={(value) => {
                    setRecaptch(value);
                  }}
                  onExpired={() => {
                    setRecaptch("");
                    recaptchaRef.current.reset();
                  }}
                />

                {/* BTN */}
                <div
                  onClick={() => handleSubmit()}
                  className={`flex ${isAllowSubmit
                    ? "cursor-pointer"
                    : "cursor-not-allowed opacity-70"
                    } h-[50px] w-[246px] items-center justify-center rounded-[8px] border-[1px] border-none border-[#2275A1] bg-[#2275A1] text-SmallSize font-medium text-white max_md:h-[50px] max_md:w-[200px]`}
                >
                  <div className="text-[20px] font-semibold max_md:text-[18px] ">
                    Submit
                  </div>
                </div>
                <Alert
                  show={show}
                  color="green"
                  dismissible={{
                    onClose: () => setShow(false),
                  }}
                  className="absolute bottom-full right-0 max-w-screen-md"
                >
                  Your request was successfully submitted.
                </Alert>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TechnologyDetails;
