import React from "react";

const ProjectCount = ({ count = "200+", title = "title" }) => {
  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <div className="text-[34px] max_sm:text-[26px] max_sm:leading-[35px] font-bold leading-[50px] text-expressionism-green">
          {count}
        </div>
        <div className="text-LargeSize font-semibold max_sm:text-SmallSize max_sm:leading-[18px] leading-[24px] text-bateau">
          {title}
        </div>
      </div>
    </>
  );
};

export default ProjectCount;
