import React, { useState } from "react";
import LOGOSVG from "../../assets/common/svgs/iGeek.svg";
import FooterBG from "../../assets/common/images/footerBG.png";
import { ButtonFilled } from "./Buttons";
import { ReactComponent as Facebook } from "../../assets/common/svgs/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/common/svgs/instagram.svg";
import { ReactComponent as LinkedIn } from "../../assets/common/svgs/linkedin.svg";
import { ReactComponent as Twitter } from "../../assets/common/svgs/twitter.svg";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const [FoterMenu] = useState([
    {
      title: "Help & Advice",
      options: [
        {
          link: "/career",
          title: "Career",
        },
        {
          link: "/about-us",
          title: "About Us",
        },
        {
          link: "/contact-us",
          title: "Contact Us",
        },
        {
          link: "/privacy-policy",
          title: "Privacy Policy",
        },
        {
          link: "/",
          title: "Terms & Conditions",
        },
      ],
    },
    {
      title: "Services",
      options: [
        {
          link: "/ai-ml",
          title: "AI & ML",
        },
        {
          link: "/services-ui-ux",
          title: "UI/UX Design",
        },
        {
          link: "/services-product-development",
          title: "Product Development",
        },
        {
          link: "/services-testing",
          title: "Quality Analysis",
        },
        {
          link: "/services-web-development",
          title: "Web Development",
        },
        {
          link: "/services-developer",
          title: "Staff Augmentation",
        },
        {
          link: "/services-mobile-development",
          title: "Mobile App Development",
        },
      ],
    },
  ]);

  return (
    <>
      <div className="relative m-auto mt-[145px] max-w-[1240px] overflow-hidden max_xl:max-w-[940px] max_md:px-[50px] max_sm:mt-[80px] max_sm:px-[20px]">
        <div className="absolute bottom-0 z-[-1] flex w-full justify-center">
          <img
            draggable="false"
            className="h-full object-cover"
            src={FooterBG}
            alt=""
          />
        </div>
        <div>
          {/* DETAILS */}
          <div className="flex flex-wrap justify-between gap-[20px] pb-[30px] max_md:mb-[20px] max_md:gap-x-[10px]">
            <div className="flex w-[190px] flex-col">
              <div className="w-[168px] max_sm:w-[120px]">
                <img draggable="false" src={LOGOSVG} alt="logo" />
              </div>
              <div className="FooterContent pt-[25px] max_md:pr-[20px]">
                Want to talk about your project?
              </div>
              <div className="w-[190px] pt-[78px]">
                <a
                  href="https://calendly.com/igeek/consultation-with-igeek"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <ButtonFilled
                    title="Talk to Our Experts"
                    className="!px-[16px]"
                  />
                </a>
              </div>
            </div>
            {FoterMenu.map((menu, index) => (
              <div key={index} className="flex flex-col ">
                <div className="FooterHeading pb-[27px]">{menu.title}</div>
                {menu.options.map((opt, index1) => (
                  <div
                    onClick={() => {
                      navigate(opt.link);
                    }}
                    key={index1}
                    className="FooterContent cursor-pointer pt-[15px]"
                  >
                    {opt.title}
                  </div>
                ))}
              </div>
            ))}
            <div className="flex w-fit flex-col items-start max_md:mb-[15px] max_sm:w-full max_sm:flex-row max_sm:justify-between">
              <div>
                <div className="FooterHeading pb-[27px] max_sm:mb-[0px]">
                  HR Inquiry
                </div>
                <a
                  className="flex items-center gap-[10px]"
                  href="mailto:hr@igeektech.com"
                  onClick={() => "window.location=another.html"}
                >
                  <div className="FooterContent">hr@igeektech.com</div>
                </a>
                <a href="tel:+91 79908 37550">
                  <div className="FooterContent pt-[15px]">+91 79908 37557</div>
                </a>
              </div>
              <div>
                <div className="FooterHeading pb-[27px] pt-[40px] max_sm:mt-[0px]">
                  Sales Inquiry
                </div>
                <a
                  className="flex items-center gap-[10px]"
                  href="mailto:sales@igeektech.com"
                  onClick={() => "window.location=another.html"}
                >
                  <div className="FooterContent">sales@igeektech.com</div>
                </a>
                <a href="tel:+91 79908 37550">
                  <div className="FooterContent pt-[15px]">+91 79908 37557</div>
                </a>
              </div>
            </div>
          </div>

          {/* Seperator Line */}
          <div className="h-[0.75px] bg-duskwood" />

          {/* COPY RIGHTS */}
          <div className="F-JC-AI-CENTER flex-wrap justify-between gap-y-[20px] gap-x-[50px] py-[20px] max_md:justify-center">
            <div className="grid grid-cols-4 gap-x-[14px]">
              <a
                href="https://www.facebook.com/info.iGeek"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Facebook />
              </a>
              <a
                href="https://www.instagram.com/i_geektech/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Instagram />
              </a>
              <a
                href="https://in.linkedin.com/company/infoigeek"
                rel="noopener noreferrer"
                target="_blank"
              >
                <LinkedIn />
              </a>
              <a
                href="https://twitter.com/i_Geektech"
                rel="noopener noreferrer"
                target="_blank"
              >
                <Twitter />
              </a>
            </div>
            <div className="text-SmallSize font-medium text-[#104358]">
              © {new Date().getFullYear()} iGeek All Right Reserved.
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                {" "}
                Privacy Policy
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
