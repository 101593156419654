import React, { Fragment } from "react";
import LinesSVG from "../../assets/common/svgs/whyIGeekBG.svg";
import WhiteIGeekSVG from "../../assets/common/svgs/whiteIGeek.svg";
import IGeekCard from "../IGeekCard";
import AgileSVG from "../../assets/common/svgs/whyiGeek/agile.svg";
import GlobalSVG from "../../assets/common/svgs/whyiGeek/global.svg";
import CuttingSVG from "../../assets/common/svgs/whyiGeek/curting.svg";
import TrackSVG from "../../assets/common/svgs/whyiGeek/track.svg";
import { useState } from "react";

const WhyIGeek = () => {
  const [data] = useState([
    {
      title: "Expertise & Innovation",
      icon: AgileSVG,
      desc: "Our combined expert knowledge with continuous innovation to bring your vision into reality.",
    },
    {
      title: "Flexible Engagement Model",
      icon: GlobalSVG,
      desc: "We adapts to your project needs and timelines, ensuring optimal collaboration and cost-efficiency.",
    },
    {
      title: "Cutting-Edge Technologies",
      icon: CuttingSVG,
      desc: "We harness the power of Pinnacle Technologies to keep you ahead of the technological curve.",
    },
    {
      title: "Transparency & Commitment",
      icon: TrackSVG,
      desc: "iGeek champions reliable and transparent culture, embedding these values into every project.",
    },
  ]);

  return (
    <>
      <div className="m-auto w-full max-w-[1240px] max_xl:max-w-[940px] max_md:px-[50px] max_sm:px-[20px]">
        {/* <DeviderLine title="PROCESS" right="right-[50%]" /> */}
        <div className="flex flex-col items-center justify-center">
          <div className="PageTitle mt-[0px] mb-[16px]">Why Choose iGeek?</div>
          <div className="PageContent mb-[80px] w-full max-w-[925px] text-center">
            Experience the Power of iGeek! Unleash unparalleled innovation and
            expert solutions that propel your business into tomorrow. Choose
            confidence. Choose iGeek.
          </div>
        </div>

        <div className="flex flex-col items-center justify-center max_md:mb-[30px]">
          <div className="cursor-pointer text-SmallSize font-medium text-her-sub-heading">
            <div
              style={{
                background:
                  "linear-gradient(45deg,rgba(34,117,161,1) 0%, rgba(79,189,150,1) 70%)",
              }}
              className={`flex h-[60px] w-[230px] items-center justify-center rounded-[6px] bg-gradient-to-r from-expressionism-green to-bateau text-white`}
            >
              <div className="text-[11px] font-semibold ">
                <img draggable="false" src={WhiteIGeekSVG} alt="" />
              </div>
            </div>
          </div>
          <div className="max_xl:w-[725px] max_md:hidden max_md:w-[470px]">
            <img draggable="false" src={LinesSVG} alt="" />
          </div>
        </div>

        <div className="flex flex-wrap justify-between max_md:m-auto max_md:w-[470px] max_md:justify-center max_md:gap-y-[20px] max_sm:w-[300px] max_sm:justify-center">
          {data.map((itm, index) => (
            <Fragment key={index}>
              <IGeekCard data={itm} />
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default WhyIGeek;
