import React from "react";

const InputField = ({
  type = "",
  error = "",
  value = "",
  name = "name",
  label = "label",
  placeholder = "Placeholder",
  onChange = () => {},
  onBlur = () => {},
}) => {
  return (
    <>
      <div className="relative">
        <div className="absolute bottom-[-24px] text-[12px] text-red-400">
          {error}
        </div>
        <label
          className={`text-RegularSize font-medium text-her-sub-heading max_md:text-[14px]`}
        >
          {label}
        </label>
        <input
          style={{
            WebkitAppearance: "none",
          }}
          onBlur={onBlur}
          type={type}
          required="required"
          autoComplete="off"
          className="focus-visible:none mt-[9px] h-[50px] w-full rounded-[8px] bg-moon-white px-[24px] text-[17px] font-medium text-duskwood focus:shadow-[0_0_0_1px_#2275a1] focus:outline-none focus:outline-[1px] focus:outline-offset-0 max_xl:px-[16px] max_md:text-[14px]"
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
        />
      </div>
    </>
  );
};

export default InputField;
