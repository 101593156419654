import React from "react";
import Process from "../Components/pageComponent/Process";
import { ButtonGradientIcon } from "../Components/utils/Buttons";
import Technology from "../Components/pageComponent/Technology";
import HireUs from "../Components/pageComponent/HireUs";
import Testimonial from "../Components/pageComponent/Testimonial";
import WhyIGeek from "../Components/pageComponent/WhyIGeek";
import HomeBottomBG1 from "../assets/common/svgs/BottomBG.svg";
import Layout from "../Components/utils/Layout";
import ServiceComp from "../Components/pageComponent/Service";

const Home = () => {
  return (
    <>
      <Layout>
        {/* HERO SECTION */}
        <div className="relative mt-[100px] flex h-screen items-center justify-center pb-[350px]">
          <div className="absolute bottom-[-5px] flex h-[344px] w-full justify-center">
            <img
              draggable="false"
              className="h-full object-cover"
              src={HomeBottomBG1}
              alt=""
            />
          </div>

          <div className="flex flex-col items-center justify-center text-center">
            <div className="HeadingText mt-[100px] max-w-[880px] max_md:px-[50px] max_sm:px-[20px]">
              Empowering Your Business Through Innovative IT Solutions
            </div>
            <div className="SubHeadingText mt-[40px] max-w-[858px] max_md:px-[50px] max_sm:px-[20px]">
              We specialize in creating custom software solutions that enable
              businesses to optimize their processes and achieve lasting growth.
            </div>
            <div className="mt-[40px]">
              <a
                href="https://calendly.com/igeek/consultation-with-igeek"
                target="_blank"
              >
              <ButtonGradientIcon
                title="Consult iGeek"
                background="#2275A1"
              />
              </a>
            </div>
          </div>
        </div>

        {/* SERVICS */}
        <ServiceComp />

        {/* Process */}
        <Process />

        {/* Technology */}
        <Technology />

        {/* HireUs */}
        <HireUs />

        {/* Testimonial */}
        <div className="m-auto max-w-[1920px]">
          <Testimonial />
        </div>

        {/* WhyIGeek */}
        <WhyIGeek />
      </Layout>
    </>
  );
};

export default Home;
