import React from "react";

const BenefitCard = ({ IMAGE = "", title = "" }) => {
  return (
    <div className="flex flex-col justify-center items-center rounded-[10px] shadow-[0px_2px_16px_0px_rgba(0,0,0,0.1)] py-[30px] gap-[27px]">
      <div className="w-[86px] h-[86px] bg-[#F6FCFF] rounded-full flex justify-center">
        <img draggable="false" className="w-[46px] h-[46px] bg-[#F6FCFF] m-auto" src={IMAGE} alt="" />
      </div>
      <div className="text-RegularSize max_sm:text-SmallSize font-semibold text-her-sub-heading">{title}</div>
    </div>
  );
};

export default BenefitCard;
