import axios from "axios";

const instance = axios.create({
  // baseURL: "https://ee20-2405-201-200d-286c-d9b3-6164-8db8-2f33.ngrok-free.app/",
  baseURL: "https://igeektech.com/api/",
});

export const SubmitForm = async (token, data) => {
  try {
    const response = await instance.post("mails/send", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (err && err.response && err.response.data && err.response.data.error) {
      return err;
    }
  }
};
export const SubmitJobForm = async (token, data) => {
  try {
    const response = await instance.post("mails/application", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    if (err && err.response && err.response.data && err.response.data.success === false) {      
      return err;
    }
  }
};
